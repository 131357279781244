import './App.css';
import ComputerComponent from './ComputerComponent';
import MobileComponent from './MobileComponent';
import React from 'react';
import * as rdd from 'react-device-detect';

function App({ settings }) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const zoek = urlSearchParams.get('zoeken');
  const hiddenCategories = ["united content", "3 letter", "overig", "overige", "loungeroom", "3 letter", "4 letter", "united", "content", "tijd", "woord", "letter", "3", "4"];
  const mobile = <MobileComponent zoek={zoek} language={settings.language} hiddenCategories={hiddenCategories}/>
  const computer = <ComputerComponent zoek={zoek} language={settings.language} hiddenCategories={hiddenCategories}/>

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }
    window.addEventListener('resize', handleResize)
    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    (rdd.isMobile || dimensions.width < 1075) ? mobile : computer
  );
}

export default App;
