
import { ReactiveBase, DataSearch, MultiList, ReactiveList, RangeInput, SelectedFilters, MultiDropdownList } from '@appbaseio/reactivesearch';

function capitalizeFirstLetter(item) {
  return item.charAt(0).toUpperCase() + item.slice(1);
}

function MobileComponent(props) {
  const nl = props.language === "nl" ? true : false;
  const hiddenCategories = props.hiddenCategories;
  return (
    <ReactiveBase
      url="https://dobby.domeinenbank.nl"
      app="search"
    >
      <div id="shopwidget">
          <div style={{ display: "flex", flexDirection: "row", width: "100%", margin: "5% 10% 0% 5%" }}>
            <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
              <DataSearch
                componentId="zoeken"
                className="search"
                dataField={[
                  {
                    "field": "name",
                    "weight": 5
                  },
                  {
                    "field": "autosuggest",
                    "weight": 1
                  },
                  {
                    "field": "categories",
                    "weight": 1
                  },
                ]}
                placeholder={nl ? "Zoek op domeinnaam of categorie" : "Search for domain name or category"}
                showClear={true}
                defaultValue={props.zoek}
                URLParams={true}
              />
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", width: "100%", margin: "2% 5% 2% 5%" }}>
            <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
              <SelectedFilters
                className="filters"
                showClearAll={'default'}
                clearAllLabel="Verwijder filters"
                render={(props) => {
                  const { selectedValues, setValue } = props;
                  const clearFilter = (component) => {
                    setValue(component, null);
                  };

                  const filters = Object.keys(selectedValues).map((component) => {
                    if (!selectedValues[component].value || selectedValues[component].value.length === 0) return null;
                    if (!selectedValues[component].showFilter) return null;

                    if (selectedValues[component].label === "extensie"){
                      return (
                        <div>
                        <div class="filterbutton">
                          {
                            `${selectedValues[component].label}: ${selectedValues[component].value.join(", ")}`
                          }
                        <button
                          key={component}
                          onClick={() => clearFilter(component)}
                          class="filterbutton"
                        >
                          {"     x"}
                        </button>
                        </div>
                        </div>
                      );
                    } else if (selectedValues[component].label === "zoeken"){
                      return (
                        <div>
                        <div class="filterbutton">
                          {
                            `${selectedValues[component].label}: ${selectedValues[component].value}`
                          }
                        <button
                          key={component}
                          onClick={() => clearFilter(component)}
                          class="filterbutton"
                        >
                          {"     x"}
                        </button>
                        </div>
                        </div>
                      );
                    } else {
                      return (
                        <div>
                        <div class="filterbutton">
                          {
                            `${selectedValues[component].label}: ${selectedValues[component].value.join(" - ")}`
                          }
                        <button
                          key={component}
                          onClick={() => clearFilter(component)}
                          class="filterbutton"
                        >
                          {"     x"}
                        </button>
                        </div>
                        </div>
                      );
                    }
                  });

                  return filters;
                }}
              />
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", width: "100%", margin: "2% 5% 2% 5%" }}>
            <div style={{ display: "flex", flexDirection: "column", width: "90%", maxWidth: '500px' }}>
              <div style={{ marginBottom: "0px", marginTop: '0px' }}>
                <MultiList
                  className="checkboxfilter"
                  react={{
                    and: ["zoeken", "verkoopprijs", "tekens", "verhuurprijs", "categorie"]
                  }}
                  innerClass={{
                    label: 'checkboxlabel',
                    input: 'checkboxinput',
                    checkbox: 'checkbox-mobile',
                    list: 'checkboxlist-mobile',
                  }}
                  componentId="extensie"
                  dataField="tld"
                  title={nl ? "Extensie" : "Extension"}
                  aggregationSize={5}
                  showSearch={false}
                  showCount={false}
                  URLParams={true}
                  transformData={(items) => {
                    var new_items = []
                    items.forEach(item => {
                      if (item.doc_count >= 10){
                        new_items.push(item)
                      }
                    })
                    return new_items
                  }}
                />
              </div>

              <div style={{ marginBottom: "0px", marginTop: '0px' }}>
                <MultiDropdownList
                  className="dropdownfilter dropdownfilter-mobile"
                  react={{
                    and: ["zoeken", "verkoopprijs", "tekens", "verhuurprijs", "extensie"]
                  }}
                  componentId="categorie"
                  dataField="categories"
                  title={nl ? "Categorie" : "Category"}
                  showSearch={true}
                  URLParams={true}
                  sortBy="asc"
                  placeholder={nl ? "Selecteer categorie" : "Select category"}
                  searchPlaceholder={nl ? "Type om te zoeken" : "Type to search"}
                  innerClass={{
                    select: 'dropdownfilter-select',
                  }}
                  transformData={(items) => {
                    var new_items = []
                    items.forEach(item => {
                      if (item.doc_count >= 50 && !hiddenCategories.includes(item.key.toLowerCase())){
                        new_items.push(item)
                      }
                    })
                    return new_items
                  }}
                  renderItem ={(label) => {
                    if(label.toLowerCase() === "vrije"){
                      return capitalizeFirstLetter(label + " tijd")
                    } else if (label.toLowerCase() === "engels"){
                      return capitalizeFirstLetter(label + " woord")
                    } else {
                      return capitalizeFirstLetter(label)
                    }
                  }}
                />
              </div>

              <div class="slider-filter-mobile" style={{ marginBottom: "0px", marginTop: '0px' }}>
                <RangeInput
                  componentId="tekens"
                  innerClass={{
                    "slider-container": "slider-mobile",
                    "input-container": "inputslider-mobile"
                  }}
                  react={{
                    and: ["zoeken", "extensie", "verkoopprijs", "verhuurprijs", "categorie"]
                  }}
                  dataField="total_length"
                  title={nl ? "Aantal tekens" : "Number of characters"}
                  showHistogram={false}
                  URLParams={true}
                  range={{
                    start: 0,
                    end: 25,
                  }}
                />
              </div>

              <div class="slider-filter-mobile" style={{ marginBottom: "0px", marginTop: '0px' }}>
                <RangeInput
                  componentId="verkoopprijs"
                  innerClass={{
                    "slider-container": "slider-mobile",
                    "input-container": "inputslider-mobile"
                  }}
                  react={{
                    and: ["zoeken", "extensie", "tekens", "verhuurprijs"]
                  }}
                  dataField="shop_price"
                  title={nl ? "Verkoopprijs" : "Sale price"}
                  showHistogram={false}
                  URLParams={true}
                  range={{
                    start: 0,
                    end: 10000,
                  }}
                  includeNullValues={true}
                />
              </div>

              <div class="slider-filter-mobile" style={{ marginBottom: "0px", marginTop: '0px' }}>
                <RangeInput
                  componentId="verhuurprijs"
                  innerClass={{
                    "slider-container": "slider-mobile",
                    "input-container": "inputslider-mobile"
                  }}
                  react={{
                    and: ["zoeken", "extensie", "tekens", "verkoopprijs", "categorie"]
                  }}
                  dataField="rental_price"
                  title={nl ? "Verhuurprijs" : "Rental price"}
                  showHistogram={false}
                  URLParams={true}
                  range={{
                    start: 0,
                    end: 100,
                  }}
                  includeNullValues={true}
                />
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ display: "flex", flexDirection: "column", width: "100%", margin: "2% 5% 2% 5%" }}>
              <ReactiveList
                className="results"
                URLParams={true}
                componentId="results"
                size={10}
                pagination={true}
                innerClass={{
                  pagination: "pagebutton",
                  resultStats: "resultstats"
                }}
                showEndPage={true}
                scrollOnChange={false}
                renderResultStats={
                  function(stats){
                    return (
                      <div class="resultstats">{nl ? `${stats.numberOfResults} resultaten` : `${stats.numberOfResults} results`}</div>
                  )
                  }
                }
                renderPagination={({ pages, totalPages, currentPage, setPage, fragmentName }) => {
                  const selectPage = Number.isFinite(totalPages) && (
                    <div class="paginationbuttons">
                        {new Array(totalPages+2).fill(1).map((item, i) => {
                          if (i === 0) {
                            return (
                              <button class="paginationbutton previouspaginationbutton"
                                key={`${-1}-${fragmentName}`}
                                value={currentPage-1}
                                onClick={e => setPage(parseInt(e.target.value, 10))}
                                disabled={currentPage === 0}
                              >
                                {nl ? "Vorige" : "Previous"}
                              </button>
                            )
                          } else if (currentPage+1 === i) {
                            return (
                              <button class="paginationbutton currentpaginationbutton"
                                key={`${i}-${fragmentName}`}
                                value={i-1}
                                onClick={e => setPage(parseInt(e.target.value, 10))}
                              >
                                {i}
                              </button>
                            )
                          } else if (i === totalPages+1){
                            return (
                              <button class="paginationbutton nextpaginationbutton"
                                key={`${+1}-${fragmentName}`}
                                value={currentPage+1}
                                onClick={e => setPage(parseInt(e.target.value, 10))}
                                disabled={currentPage === totalPages-1}
                              >
                                {nl ? "Volgende" : "Next"}
                              </button>
                            )
                          } else if ([1, currentPage, currentPage+2, totalPages].includes(i)) {
                            return (
                              <button class="paginationbutton otherpaginationbutton"
                                key={`${i}-${fragmentName}`}
                                value={i-1}
                                onClick={e => setPage(parseInt(e.target.value, 10))}
                              >
                                {i}
                              </button>
                            )
                          } else if ((i < currentPage-1 && i === 2) || (i > currentPage+1 && i === totalPages-1)) {
                            return '  . . .  '
                          } else {
                            return null
                          }
                        }
                        )}
                    </div>
                );

                return selectPage;
                }}
                sortOptions={[
                  {
                    label: nl ? "Alfabetisch - oplopend" : "Alphabetically - ascending",
                    dataField: "name",
                    sortBy: "asc"
                  },
                  {
                    label: nl ? "Alfabetisch - aflopend" : "Alphabetically - descending",
                    dataField: "name",
                    sortBy: "desc"
                  },
                  {
                    label: nl ? "Verkoopprijs - oplopend" : "Sale price - ascending",
                    dataField: "shop_price",
                    sortBy: "asc"
                  },
                  {
                    label: nl ? "Verkoopprijs - aflopend" : "Sale price - descending",
                    dataField: "shop_price",
                    sortBy: "desc"
                  },
                  {
                    label: nl ? "Verhuurprijs - oplopend" : "Rental price - ascending",
                    dataField: "rental_price",
                    sortBy: "asc"
                  },
                  {
                    label: nl ? "Verhuurprijs - aflopend" : "Rental price - descending",
                    dataField: "rental_price",
                    sortBy: "desc"
                  }
                ]}
                defaultSortOption={nl ? "Alfabetisch - oplopend" : "Alphabetically - ascending"}
                react={{
                  and: ["zoeken", "extensie", "verkoopprijs", "tekens", "verhuurprijs", "categorie"]
                }}>
                {({ data }) => (
                  <table class="mobile">
                    <tbody>
                      <tr>
                        <th style={{whiteSpace: 'pre-line'}} class="domainname-mobile">{nl ? "Domein\nnaam" : "Domain\nname"}</th>
                        <th style={{whiteSpace: 'pre-line'}} class="saleprice">{nl ? "Verkoop\nprijs" : "Sale\nprice"}</th>
                        <th style={{whiteSpace: 'pre-line'}} class="rentalprice">{nl ? "Verhuur\nprijs" : "Rental\nprice"}</th>
                      </tr>
                      {data.map(item => (
                        <tr>
                          <td class="domainname"><a class="domainlink-mobile" title={ item.tld === 'eu' ? 'https://domeinenbank.nl/en/buy-or-rent-this-domain/?domain=' + item.name : 'https://domeinenbank.nl/koop-deze-domeinnaam/?domain=' + item.name } href={ item.tld === 'eu' ? 'https://domeinenbank.nl/en/buy-or-rent-this-domain/?domain=' + item.name : 'https://domeinenbank.nl/koop-deze-domeinnaam/?domain=' + item.name }>{item.name.length <= 15 ? item.name : item.name.substring(0, 15)+'...'}</a></td>
                          <td class="saleprice">{item.present_with_price_range? '€' + item.price_range_min + ' - €' + item.price_range_max : item.shop_price ? '€' + item.shop_price : nl ? "op aanvraag" : "on demand"}</td>
                          <td class="rentalprice">{item.rental_price ? '€' + parseFloat(item.rental_price).toFixed(2).toLocaleString().replace(/\./g, ',') : nl ? "op aanvraag" : "on demand"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>)
                }
              </ReactiveList>
            </div>
          </div>
        </div>
        </ReactiveBase>
  )
}

export default MobileComponent;
