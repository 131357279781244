import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const targets = document.querySelectorAll('.rsw-root');
Array.prototype.forEach.call(targets, target => {
  const id = target.dataset.id;
  const settings = window.rswSettings[id];
  ReactDOM.render(<App settings={settings} />, target)
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
